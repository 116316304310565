var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"location"},[_c('div',{staticClass:"input_style"},[_c('div',{staticStyle:{"width":"300px"}},[_c('el-input',{attrs:{"type":"textarea","rows":5,"placeholder":"请输入经纬度，格式：经度,纬度,经度,纬度..."},model:{value:(_vm.textarea),callback:function ($$v) {_vm.textarea=$$v},expression:"textarea"}}),_c('button',{on:{"click":_vm.addMarkers}},[_vm._v("一条固定的轨迹线")])],1),_c('div',[_c('el-button',{attrs:{"type":"success"},on:{"click":_vm.goLine}},[_vm._v("生成")])],1)]),(_vm.currentMap === 'google')?_c('GmapMap',{ref:"mapRef",staticStyle:{"width":"100%","height":"100%"},attrs:{"map-type-id":"terrain","center":_vm.center,"zoom":15,"options":{
        zoomControl: true, // 启用缩放控件
        mapTypeControl: true, // 启用地图类型切换控件
        scaleControl: true, //  启用比例尺控件
        streetViewControl: true, // 启用街景
        rotateControl: true, // 启用旋转手势
        fullscreenControl: true, // 启用全屏
        disableDefaultUI: false // 禁用默认UI控件
      }}},[(_vm.userLocation)?_c('gmap-marker',{attrs:{"position":_vm.userLocation,"icon":_vm.customIcon}}):_vm._e(),_vm._l((_vm.markers),function(position,index){return _c('gmap-marker',{key:index,attrs:{"position":position}})}),_c('gmap-polyline',{attrs:{"path":_vm.markers}})],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }