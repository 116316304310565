<template>
  <div class="location">
    <!-- 输入框 -->
    <div class="input_style">
      <div style="width: 300px;">
        <el-input type="textarea" :rows="5" placeholder="请输入经纬度，格式：经度,纬度,经度,纬度..." v-model="textarea">
        </el-input>
        <button @click="addMarkers">一条固定的轨迹线</button>
      </div>
      <div>
        <el-button type="success" @click="goLine">生成</el-button>

       
      </div>
    </div>

  
      <GmapMap ref="mapRef" v-if="currentMap === 'google'" map-type-id="terrain" :center="center" :zoom="15"
        style="width: 100%; height: 100%" :options="{
          zoomControl: true, // 启用缩放控件
          mapTypeControl: true, // 启用地图类型切换控件
          scaleControl: true, //  启用比例尺控件
          streetViewControl: true, // 启用街景
          rotateControl: true, // 启用旋转手势
          fullscreenControl: true, // 启用全屏
          disableDefaultUI: false // 禁用默认UI控件
        }">
        <!-- 用户位置标记点 -->
        <gmap-marker v-if="userLocation" :position="userLocation" :icon="customIcon"></gmap-marker>
        <!-- 标记点 -->
        <gmap-marker v-for="(position, index) in markers" :key="index" :position="position"></gmap-marker>
        <!-- 轨迹线 -->
        <gmap-polyline :path="markers"></gmap-polyline>
      </GmapMap>
  </div>
</template>

<script>
// 引入ccs 适应手机屏幕 消除原有样式
import '@/assets/css/mywindow.css';

  import { deviceStatusFormat, getObjString, convertDateTimeToString } from '@/api/util'
import { initMap,makePath,makeColorPath,makeTrackMarker } from '@/api/map';
import MyDataSource from '@/assets/data/googledata1.json'; // 一条的数据
// import MyDataSource from '@/assets/data/pigeon_5.json'; // 多条轨迹的数据 数据格式是 [[{},{}],[{},{}],[{},]]

export default {
  data() {
    return {
      map: null,
      currentMap: 'google', // 默认显示谷歌地图
      mapInstance: undefined,
      center: {
        lat: 25.037798,
        lng: 121.565170
      }, // 地图中心点坐标
      markers: [], // 标记点数组
      textarea: '',
      hasCollapse: false,
      customIcon: {
        url: require('@/assets/bike_online.png'),
        // size: { width: 32, height: 32 },
        anchor: { x: 16, y: 16 }
      },
      userLocation: null // 用户位置
    };
  },
  created() {
   
    this.checkLang(); // 新增，检查语言设置
    // this.fetchLocation(); // 获取位置点
    // this.initMapPage(); // 初始化地图
  },
  mounted() {
    let _this = this;
    window.historicalTrack = function (deviceImei) {
        _this.historicalTrack(deviceImei)
      }

    // 可以在这里添加地图加载完成后的其他操作
    setTimeout(function () { // 延迟加载地图，防止页面抖动问题

    _this.initMapPage()
    // moveToCenter(_this.mapInstance, _this.center)
    // makePath(_this.mapInstance, MyDataSource)
    makeColorPath(_this.mapInstance, MyDataSource, '#FF0000')

    let data = {
      lat: 25.037798,
      lng: 121.565170,
      deviceImei: '867585310532819'
    }
    let data2 = {
      lat: 25.137798,
      lng: 121.665170,
      deviceImei: '867585310532819'
    }
    let data3 = {
      lat: 25.237798,
      lng: 121.765170,
      deviceImei: '1234567890'
    }
    let data4 = {
      lat: 25.337798,
      lng: 121.865170,
      deviceImei: '1234567890'
    }

    makeTrackMarker(_this.mapInstance, data,"start")
    makeTrackMarker(_this.mapInstance, data2,"subordinate")
    makeTrackMarker(_this.mapInstance, data3,"supplement")
    makeTrackMarker(_this.mapInstance, data4,"end")
    }, 800)
  },
  methods: {
    checkLang () {
      let lang = 'en-US'
      if (window.navigator.language) {
        lang = window.navigator.language
      } else {
        lang = window.navigator.browserLanguage
      }

      // this.loginForm.lang = lang
      window.sessionStorage.setItem('lang', lang)
      this.$i18n.locale = lang
    },
    addMarkers() {
      this.markers = MyDataSource;
      // 更新地图中心位置
      if (this.markers.length > 0) {
        this.center = this.markers[0];
      }
    },
    goLine() {
      const parsedCoordinates = this.parseCoordinates(this.textarea);
      if (parsedCoordinates.length > 0) {
        this.markers = parsedCoordinates;
        this.center = parsedCoordinates[0];
      }
    },
    // 拿到经纬度字符串数据 生成轨迹线 
    // 120.719532,24.719532,120.819532,24.729532,120.829532,24.829532,120.869532,24.929532
    parseCoordinates(coordinateString) {
      const coordinates = coordinateString.split(',');
      let parsedData = [];
      for (let i = 0; i < coordinates.length; i += 2) {
        if (i + 1 < coordinates.length) {
          const lng = parseFloat(coordinates[i]);
          const lat = parseFloat(coordinates[i + 1]);
          if (!isNaN(lng) &&!isNaN(lat)) {
            parsedData.push({
              lng,
              lat
            });
          }
        }
      }
      return parsedData;
    },
    async fetchLocation() {
      try {
        const response = await fetch(`https://ipinfo.io/json`);
        const data = await response.json();
        console.log("ip地址", data);
        const userLat = data.loc.split(',')[0];  // 用户纬度 latitude
        const userLng = data.loc.split(',')[1];  // 用户经度 longitude

        const userTimezone = data.timezone;  // 用户时区

        this.userLocation = {
          lat: parseFloat(userLat),
          lng: parseFloat(userLng)
        };
        this.center = this.userLocation;

        // 存到本地缓存
        window.sessionStorage.setItem('userLat', userLat);
        window.sessionStorage.setItem('userLng', userLng);
        window.sessionStorage.setItem('userTimezone', userTimezone);

      } catch (err) {
        console.error('Failed to fetch location.', err);
      }
    },
    initMapPage() {
      const self = this;


      if (self.currentMap === 'google' && window.google && window.google.maps) {
        if (this.mapInstance === undefined) {
            this.mapInstance = {
              mapType: 'google',
              map: this.$refs.mapRef.$mapObject,
              mapRef: this.$refs.mapRef,
              googleMaps: window.google.maps,
              markList: [],
              polyList: [],
              currentOpenInfoWindow: undefined,
              contentFunc: this.makeInfoWindowConetnt,
              haveBtn: true
            }
          }
        // 异步执行初始化地图
        initMap(self.mapInstance, self.center, 13);
        console.log('sadf');
      } else {
        console.error('谷歌地图API未加载或当前地图类型不是谷歌地图');
        // 可以在这里添加错误处理逻辑，例如显示错误提示
      }
    },
    makeContent(data, haveBtn) {
      // 这里可以根据需要实现内容生成逻辑
    },
    makeInfoWindowConetnt (device, haveBtn) {
        const self = this
        return function () {
          let content = '<style>'
          content += '.deviceInfo { font-size: 12px; margin: 0; padding: 0;}'
          content += 'ul li { display: flex; flex-flow: row nowrap; justify-content: center; align-items: center; }'
          content += 'ul li div { width: 200px; }'
          content += '.centerDiv{ width: 100%; display: flex; justify-content: space-around; margin-top: 4px; }'
          content += '.centerDiv button { font-size: 12px; width: 60px; border-radius: 10px; color: white; }'
          content += '.buluButton { border: 1px solid #00A2E9; background-color: rgba(0, 162, 240 ,0.5); }'
          content += '.orangeButton { border: 1px solid chocolate; background-color: rgba(210, 105, 30, 0.5); } '
          content += '.redButton { border: 1px solid red; background-color: rgba(233, 10, 10, 0.5); }'
          content += '.purpleButton { border: 1px solid purple; background-color: rgba(128, 0, 128, 0.5); }'
          content += '.yellowButton { border: 1px solid #F0AC20; background-color: rgba(242, 120, 32, 0.5); }'
          content += '</style>'
          content += '<ul class="deviceInfo">'
          if (device.deviceType !== 2) {
            let item = 'engine'
            content += '<li> <div> <b>' + self.$t('device.deviceImei') + ':</b> <span>' + device.deviceImei + '</span> </div>'
            content += '<div> <b>' + self.$t('device.deviceName') + ':</b> <span>' + device.deviceName + '</span> </div> </li>'
            content += '<li> <div> <b>' + self.$t('device.lastLatitude') + ':</b> <span>' + getObjString(device.lastLatitude) + '</span> </div>'
            content += '<div> <b>' + self.$t('device.lastLongitude') + ':</b> <span>' + getObjString(device.lastLongitude) + '</span> </div> </li>'
            content += '<li> <div> <b>' + self.$t('device.accStatus') + ':</b> <span>' + deviceStatusFormat(device, item) + '</span> </div>'
            content += '<div> <b>' + self.$t('device.lastLocationTime') + ':</b> <span>' + convertDateTimeToString() + '</span> </div> </li>'
            content += '<li> <div> <b>' + self.$t('device.lastSpeed') + ':</b> <span>' + getObjString(device.lastSpeed) + '</span> </div>'
            content += '<div> <b>' + self.$t('device.heading') + ':</b> <span>' + getObjString(device.heading) + '</span> </div> </li>'
            content += '<li> <div> <b>' + self.$t('device.odometer') + ':</b> <span>' + getObjString(device.odometer) + '</span> </div>'
            content += '<div> <b>' + self.$t('device.fuel') + ':</b> <span>' + getObjString(device.fuel) + '</span> </div> </li>'
            content += '<li> <div> <b>' + self.$t('device.temperature') + ':</b> <span></span> </div>'
            content += '<div> <b>' + self.$t('device.address') + ':</b> <span></span> </div> </li>'
          } else {
            let speed = 0
            if (device.odometer && device.duration && device.duration !== 0) {
              speed = device.odometer / device.duration
            }
            let averageSpeed = 0
            if (device.accumulateOdometer && device.accumulateDuration && device.accumulateDuration !== 0) {
              averageSpeed = device.accumulateOdometer / device.accumulateDuration
            }
            content += '<li> <div> <b>' + self.$t('device.deviceImei') + ':</b> <span>' + device.deviceImei + '</span> </div>'
            content += '<div> <b>' + self.$t('device.nickname') + ':</b> <span>' + device.deviceName + '</span> </div> </li>'
            content += '<li> <div> <b>' + self.$t('device.lastDeviceVol') + ':</b> <span>' + (device.lastDeviceVol || 0) + '%' + '</span> </div>'
            content += '<div> <b>' + self.$t('device.version') + ':</b> <span>' + (device.version || '') + '</span> </div> </li>'
            content += '<li> <div> <b>' + self.$t('device.positioningTime') + ':</b> <span>' + device.lastGpsTime + '</span> </div>'
            content += '<div> <b>' + self.$t('device.uploadTime') + ':</b> <span>' + device.lastLocationTime + '</span> </div> </li>'
            content += '<li> <div> <b>' + self.$t('device.lastLatitude') + ':</b> <span>' + device.lastLatitude + '</span> </div>'
            content += '<div> <b>' + self.$t('device.lastLongitude') + ':</b> <span>' + device.lastLongitude + '</span> </div> </li>'
            // content += '<li> <div> <b>' + self.$t('device.weather') + ':</b> <span>' + device.weather + '</span> </div>'
            // content += '<div> <b>' + self.$t('device.lastPositionDesc') + ':</b> <span>' + device.lastPositionDesc + '</span> </div> </li>'
            content += '<li> <div> <b>' + self.$t('device.distanceFromPreviousPoint') + ':</b> <span>' + (device.odometer || 0) + self.$t('unit.meter') + '</span> </div>'
            content += '<div> <b>' + self.$t('device.timeFromPreviousPoint') + ':</b> <span>' + (device.duration || 0) + self.$t('unit.second') + '</span> </div> </li>'
            content += '<li> <div> <b>' + self.$t('unit.speedPerMinute') + ':</b> <span>' + (speed * 60).toFixed(4) + self.$t('unit.meterPerMinute') + '</span> </div>'
            content += '<div> <b>' + self.$t('unit.speedPerHour') + ':</b> <span>' + (speed * 3.6).toFixed(4) + self.$t('unit.kilometerPerHour') + '</span> </div> </li>'
            content += '<li> <div> <b>' + self.$t('unit.averageSpeedPerMinute') + ':</b> <span>' + (averageSpeed * 60).toFixed(4) + self.$t('unit.meterPerMinute') + '</span> </div>'
            content += '<div> <b>' + self.$t('unit.averageSpeedPerHour') + ':</b> <span>' + (averageSpeed * 3.6).toFixed(4) + self.$t('unit.kilometerPerHour') + '</span> </div> </li>'
            content += '<li> <div> <b>' + self.$t('device.altitude') + ':</b> <span>' + (device.altitude || 0) + self.$t('unit.meter') + '</span> </div>'
            content += '<div> <b>' + self.$t('device.locationPoints') + ':</b> <span>' + (device.pointIndex || '') + '</span> </div> </li>'
          }
          if (haveBtn) {
            content += '<li> <div class="centerDiv">'
            content += '<button class="buluButton" onclick="realTimeTrack(\'' + device.deviceImei + '\')"" type="button"> ' + self.$t('devicePage.realTimeTrack') + ' </button>'
            content += '<button class="orangeButton" onclick="historicalTrack(\'' + device.deviceImei + '\')" type="button">  ' + self.$t('devicePage.historicalTrack') + '  </button>'
            content += '<button class="redButton" onclick="flightTraining(\'' + device.deviceImei + '\')" type="button">  ' + self.$t('devicePage.flightTraining') + '  </button>'
            // content += '<button onclick="sendCommand(\'' + device.deviceImei + '\')" type="button">  ' + self.$t('devicePage.sendCommand') + '  </button>'
            content += '<button class="purpleButton" onclick="modifyConfiguration(\'' + device.deviceImei + '\')" type="button">  ' + self.$t('devicePage.config') + '  </button>'
            content += '<button class="yellowButton" onclick="getDeviceInfo(\'' + device.deviceImei + '\')" type="button">  ' + self.$t('devicePage.getDeviceInfo') + ' </button>'
            content += '</div> </li>'
          }
          content += '</ul>'
          return content
        }
      },
      historicalTrack (deviceImei) {
        closeCurrentInfoWindow(this.mapInstance, deviceImei)
        let routeData = this.$router.resolve({
          path: '/device/historicalTrack'
        })
        window.sessionStorage.setItem('historicalTrackDeviceImei', deviceImei)
        window.open(routeData.href, '')
      },
      realTimeTrack (deviceImei) {
       console.log(deviceImei)
      },
  },
};
</script>

<style lang="less" scoped>
.location {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.mapContainer.collapseList {
  width: 100%;
}

.mapContainer {
  height: 100%;
  width: calc(100%);
  float: left;
}

.collapseImg.collapseList {
  left: 0;
}

.deviceList.collapseList {
  display: none;
}

.input_style {
  z-index: 8;
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 50px;
}
</style>
